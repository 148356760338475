.Footer-container{
    position: relative;
}

.Footer-container>hr{
    border: 1px solid var(--lightgray);
}

.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.social-media{
    display: flex;
    gap: 4rem;
}

.social-media>a>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.social-media>a>img:hover{
    background: rgb(177, 121, 23);
}

.logo-f>img{
    width: 11rem;
}

.f-blur-1{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(150px);
    background: rgb(225,115,0);
}