.header{
    display: flex;
    justify-content: space-between;
}

.logo{
    opacity: 0.9;
    border-radius:30px;
    width: 20rem;
    height: 5rem;
}

.header-menu{
    list-style: none;
    display: flex;
    font-size: 1.2rem;
    gap: 2rem;
    color: white;
}

.header-menu>li:hover{
    cursor: pointer;
    color: var(--orange);
}

@media screen and (max-width: 768px){
    .logo{
        transform: scale(0.7);
        position: relative;
        left: -4rem;
        top:-1rem;
        opacity: 0.9;
        border-radius:30px;
    }

    .header>:nth-child(2){
        position: fixed;
        right:2rem;
        z-index: 100;
    }

    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 1rem;
    }
}