.plans-container{
    margin-top: -8rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}

.plans-blur-1{
    width: 22rem;
    height: 30rem;
    left:0;
}

.plans-blur-2{
    width: 22rem;
    height: 30rem;
    right:0;
}

.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:3rem;
    margin-top: 4.1rem;
}

.plan{
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: white;
    gap:2rem;
    padding: 1.5rem;
    width:15rem;
}

.plan:nth-child(2){
    background: var(--planCard);
    transform:scale(1.1);
}

.plan>svg{
    width: 2rem;
    height: 2rem;
    fill: var(--orange);
}

.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}

.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}

.plan>:nth-child(5){
    font-size: 0.8rem;
}

.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature{
    display: flex;
    align-items: center;
    gap:1rem;
}

.feature>img{
    width: 1rem;
}

.plans>:nth-child(2)>svg{
    fill: white;    
}

.plans>:nth-child(2)>button{
    color: var(--orange);
}

@media screen and (max-width: 768px){
    .programs-header{
        position: relative;
        top: -1rem;
    }

    .plans{
        flex-direction: column;
        position: relative;
        top: -1rem;
    }

    .plans>:nth-child(2){
        transform: none;
    }
}